import React, { useState, useEffect } from "react";
import {
  Grid,
  makeStyles,
  Box,
  TableContainer,
  TableHead,
  Table,
  TableCell,
  Paper,
  TableBody,
  TableRow,
  CircularProgress
} from "@material-ui/core";
import { toast } from "react-toastify";
import clsx from "clsx";
import { getPortfolioTransactionhandler, ExchangeArray } from "src/services/apiServices";
import DataNotFound from "src/components/DataNotFound";
import ButtonCircularProgress from "src/components/ButtonCircularProgress";
import PageLoading from "src/components/pageLoading";


const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTableCell-head": {
      background: "#FFFFFF",
      borderWidth: "1px 0px 2px 0px !important",
      borderStyle: "solid !important",
      borderColor: "#E7EAF3 !important",
    },
  },
  title: {
    flex: "1 1 100%",
    display: "flex",
    flexDirection: "column",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#6C757E",
  },
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    background: "#FFFFFF",
    border: "1px solid rgba(231, 234, 243, 0.2)",
    boxShadow: "0px 8px 20px rgba(65, 65, 65, 0.2)",
    borderRadius: " 8px",
  },
  mainBox: {
    backgroundColor: "#FFFFFF",
    // height:"560px"
    marginBottom: "10rem",
  },
  absolutePosition: {
    position: "relative",
    padding: "0 10%",
    marginTop: "4rem",
  },
  blocks: {
    display: "flex",
    justifyContent: "center",
  },

  // table: {
  //   overflowX: "auto",
  // },

  tableHead: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#111111",
    textAlign: "center",
  },

  tablecell: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#585858",
    textAlign: "center",
    // minWidth:'180px',

    "@media(max-width:900px)": {
      minWidth: '150px',
    },

  },
  iconBorder: {
    borderRadius: "5px !important",
  },
  flexSpan: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },
  colorPrimary: {
    background: "#E7EAF3",
    width: "100%",
    borderRadius: "5px",
  },
  barColorPrimary: {
    background: "#D64F49",
  },
  colorPrimary2: {
    background: "#E7EAF3",
    width: "100%",
    borderRadius: "5px",
  },
  barColorPrimary2: {
    background: "#DB9A04",
  },
  flexSerial: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "center"
  },
  flexSerial1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },

  methodCell: {
    borderRadius: "5px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#585858",
    padding: "8px 0",
    textAlign: "center",
  },

  transactiontxt: {
    fontFamily: "Poppins !important",
    fontSize: "32px",
    fontWeight: "600",
    color: "#495057",
  },
}));

const PortfolioComponent = () => {
  const classes = useStyles();
  const [textToCopy, setTextToCopy] = useState("");
  const [transactionList, setTransactionList] = useState([]);
  const [loading, setLoading] = useState(true)

  const copyTextToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Address copied to clipboard");
      })
      .catch(() => {
        toast.error("Failed to copy text");
      });
  };

  useEffect(() => {
    getTranslations();
  }, [])
  const getTranslations = async () => {
    const address = localStorage.getItem("walletAddress");
    const data = {
      module: "account",
      action: "tokenlist",
      address: address
    }
    setLoading(false)
    try {
      setLoading(true)
      const response = await getPortfolioTransactionhandler("portfolio", data);
      if (response) {
        const filterArray = response.filter((data)=>{
          return data.name
        })
        let newArray = [];
         for (let i = 0 ; i < filterArray.length ; i++){
            newArray.push({...filterArray[i], ...ExchangeArray[i]})
         }        
         setTransactionList(newArray);
        setLoading(false);
      }

    } catch (error) {
      setLoading(false);
      console.log(error)
    }finally {
      setLoading(false);
    }
  }
  return (
    <>
       {loading ? (
        <PageLoading />
      ) : (
      <Grid className={classes.mainBox}>
        <Box className={classes.absolutePosition}>
          <Box className={classes.transactionContainer} mb={2}>
            <h1 className={classes.transactiontxt}>
              Portfolio
            </h1>
          </Box>

          <div className={classes.root}>
            <Paper className={classes.paper}>
              {loading ? (
                <ButtonCircularProgress />
              ) : (
                <>
                  <TableContainer>
                    <Table arial-label="simple table" className={classes.table}>
                      <TableHead>
                        <TableRow className={classes.root}>
                          <TableCell className={classes.tableHead}>Symbol</TableCell>
                          <TableCell className={classes.tableHead}>
                            Name
                          </TableCell>
                          <TableCell
                            className={classes.tableHead}
                            style={{ textAlign: "center" }}
                          >
                            Decimal
                          </TableCell>

                          <TableCell className={classes.tableHead}>Contract Address</TableCell>
                          <TableCell className={classes.tableHead}>Type</TableCell>
                          <TableCell className={classes.tableHead}>Supply</TableCell>
                        </TableRow>
                      </TableHead>
                      {transactionList && transactionList.map((value) => {
                        return (
                          <TableBody>
                            <TableRow>
                              <TableCell className={classes.tablecell}>
                                <div
                                  className={clsx(
                                    classes.flexSerial,
                                    classes.methodCell
                                  )}
                                >
                                  <img src={value.img} alt="" />
                                </div>
                              </TableCell>

                              <TableCell className={classes.tablecell}>
                                <div className={classes.methodCell}>
                                  {value?.name ? value?.name : "-"}
                                </div>
                              </TableCell>

                              <TableCell className={classes.tablecell}>
                                {value?.decimals ? value?.decimals : "-"}

                              </TableCell>

                              <TableCell className={classes.tablecell}>
                                <div className={classes.flexSerial1}>
                                  <p className={classes.tablecell}>{value?.contractAddress ? value?.contractAddress : "-"}</p>
                                  <img src="/images/copyimg.svg" alt="" onClick={() => copyTextToClipboard(value?.contractAddress)} style={{ cursor: "pointer" }} />
                                </div>
                              </TableCell>

                              <TableCell>
                                <div className={classes.flexSerial1}>
                                  <p className={classes.tablecell}>
                                    {value?.type ? value?.type : "-"}
                                  </p>
                                </div>
                              </TableCell>
                              <TableCell className={classes.tablecell}>
                                <div
                                  className={classes.flexSerial}
                                  style={{ gap: "5px" }}
                                >
                                  <p className={classes.tablecell}>{value?.balance + " " + value?.name ? value?.balance + " " + value?.name : "-"}</p>
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )
                      })}
                    </Table>
                  </TableContainer>
                  {
                    (transactionList.length === 0 && <DataNotFound />)}
                </>
              )}
            </Paper>
          </div>

        </Box>
      </Grid>
      )}
    </>
  );
};

export default PortfolioComponent;
