import React from 'react';
import Header from "src/components/header";
import Dashboard from "src/views/pages/dashboard"

function Index() {
    return (
        <>
        <Header />
        <div>
        <Dashboard />
        </div>
        </>
    )
}

export default Index