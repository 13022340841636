import React,{useState} from 'react';
import { AppBar, Toolbar, Typography, IconButton, useMediaQuery, useTheme, Grid,  Drawer,
    List,
    ListItem,
    ListItemText, } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation, Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        "& .MuiPaper-root": {
            boxShadow: "none"
        },
        "& .MuiDrawer-paperAnchorLeft":{
            width:"60%"
        }
    },
    title: {
        flexGrow: 1,
        textAlign: 'center',
        color: "#000000",
        fontStyle: "Poppins",
        fontWeight: "500",
        fontSize: "20px",
        lineHeight: "22px",
        [theme.breakpoints.up('sm')]: {
            textAlign: 'left',
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color:"#000",
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    globalPadding: {
        padding: "0 13%"
    },
    itemsGap: {
        gap: "4%"
    },
    headerBack: {
        backgroundColor: "#FFFFFF",
        borderBottomStyle: "solid ",
        borderColor: "#E0E0E0",
        borderWidth: "1px"
    },
    navItems: {
        color: "#000000",
        fontStyle: "Poppins",
        fontWeight: "400",
        fontSize: "17px",
        lineHeight: "28px",
        cursor: "pointer",
        "&:hover": {
            color: "#D64F49"
        },
        "& :selected": {
            color: "#D64F49"
        },
        "& :active": {
            color: "#D64F49"
        }
    },
    activeNavItem: {
        color: "#D64F49"
      },
}));

const Header = () => {
    const classes = useStyles();
    const history = useHistory();
  const location = useLocation(); 
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [drawerOpen, setDrawerOpen] = useState(false);
    const isActive = (path) => {
        return location.pathname === path;
      };

    const toggleDrawer = () => {
      setDrawerOpen(!drawerOpen);
    };
    const navItems = [
        {
            item: "Dashboard",
            link:"/"
        },
        {
            item: "Portfolio",
            link:"/portfolio"
        },
        {
            item: "Transactions",
            link:"/transactions"
        },
        {
            item: "Tax Reports",
            link:"/tax"

        },
    ]
    const renderMobileMenu = (
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}  PaperProps={{
        sx: { width: "60%" },
      }}>
        <div className={classes.drawer} style={{width: window.innerWidth * 0.55}}>
            
          <List>
            {/* Render mobile navigation items */}
            {navItems.map((key)=>{
                return(
                    <ListItem button onClick={toggleDrawer}>
                    <ListItemText primary={key.item} onClick={()=>{history.push(key.link)}}/>
                  </ListItem>
                )
            })}
      
          </List>
        </div>
      </Drawer>
    );

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.headerBack}>
                <Toolbar className={classes.globalPadding}>
                    {isMobile ? (
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            className={classes.menuButton}
                            onClick={toggleDrawer}
                        >
                            <MenuIcon />
                        </IconButton>
                    ) : null}
                    <div>
                        <Link to="/">
                        <img src="images/logo.png" alt=""/>
                        </Link>
                        </div>
                    {isMobile ? null : (
                        <Grid container justify="center" alignItems="center" className={classes.itemsGap}>
                            {navItems.map((key) => {
                                return (
                                    <>
                                        <Grid item sm={0} >
                                            <Typography className={`${classes.navItems} ${isActive(key.link) && classes.activeNavItem}`} onClick={()=>{history.push(key.link)}}>{key.item}</Typography>
                                        </Grid>
                                    </>
                                )
                            })}
                        </Grid>
                    )}
                </Toolbar>
            </AppBar>
            {isMobile && renderMobileMenu}
        </div>
    );
};

export default Header;
