import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Button,
  Grid,
} from "@material-ui/core";
// import {Grid,Item} from "@mui/material";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    // width: "100%",
    // height: "100vh",
    // border: "1px solid",
    backgroundColor: "#FAFAFA",
    padding: "1%"
    },
  innerBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    // border: "1px solid red",
    height: "100%",
    alignItems: "center",
  },
  searchContainer: {
    width: "80%",
    // border: "1px solid green",
    display: "flex",
    justifyContent: "flex-start",
    gap: "1.25rem",
    alignItems: "center",
    padding: "10px",
  },
  searchtxt: {
    width: "50%",
    // backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    "& .MuiOutlinedInput-input": {
      padding: "11px",
    },
    "& .MuiOutlinedInput-root": {
      border: "none ",
      background: "#FFFFFF",
      "& input::placeholder": {
        fontSize: "13px",
        color: "#6C757D",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "400",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  },
  gridContainer: {
    width: "80%",
    // border: "1px solid blue",
    height: "100%",
    marginTop: "22px",
    padding: "10px",
  },
  searchbtn: {
    backgroundColor: "#D64F49",
    color: "#FFFFFF",
    fontFamily: "Inter",
    fontWeight: "700",
    fontSize: "14px",
    padding: "8px 23px 8px 27px",

    "@media(max-width:843px)": {
      padding: "8px",
    },

    "@media(max-width:665px)": {
      padding: "6px",
    },

    "@media(max-width:550px)": {
      fontSize: "9px",
    },
  },

  plusIcon: {
    // position: "absolute",
    // left: "0",
    borderRadius: "5px",

    "@media(max-width:550px)": {
      width: "12px"
    },

  },

  firstGrid: {
    display: "flex",
    flexDirection: "column",
    gap: "1.2rem",
    padding: "0px 10px 0px 0px !important",
  },

  walletText: {
    fontFamily: "Poppins",
fontWeight: "600",
fontSize: "32px",
lineHeight: "38.4px",
color: "#495057",
  },
  walletDiv:{ 
    display: "flex",
    justifyContent: "center",
  },
  text :{ 
    fontFamily: "Poppins",
fontWeight: "400",
fontSize: "20px",
lineHeight: "24px",
color: "#6C757D",
  }
  
}));

// const Header = () => {
//   const classes = useStyles();

const Wallet = () => {
  const classes = useStyles();

  return (
    <div>
      <Box className={classes.mainBox}>
        <Box className={classes.innerBox}>
          <Box className={classes.searchContainer}>
            {/* <TextField
              className={classes.searchtxt}
              variant="outlined"
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button variant="contained" className={classes.searchbtn}>
              {" "}
              Search{" "}
            </Button> */}

            <div style={{width: "70%"}}>
            <p className={classes.walletText}>Wallets</p>
            </div>
            <Button
              variant="contained"
              className={classes.searchbtn}
              startIcon={
                <img
                  src="images/Plus.svg"
                  alt="plusicon"
                  className={classes.plusIcon}
                />
              }
            >
              {" "}
              Add Wallet
            </Button>
          </Box>
          <div className={classes.walletDiv}>
            <p className={classes.text}> You don't have any wallets! </p>
          </div>
          </Box>
          </Box>
    </div>
  )
}


export default Wallet;
