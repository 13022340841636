// define base url and endpoints for api
export const baseUrl = 'https://explorer-posa.mobiloitte.io/';
export const url = "https://nodepune-hybridnode.mobiloitte.io/"
const ApiConfig = {
    portfolio: `${baseUrl}/api`,
    balance:`${baseUrl}/api`,
    transaction:`${baseUrl}/api`,
    capitalGaines:`${url}Tax/gettax`,
    transactionCount:`${url}Tax/gettxCount`,
    gainlossPercentange: `${url}TAX/taxInPercentage`,
}
export default ApiConfig;