import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  MenuItem,
  Select,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getprofitlossPercentageHandler } from "src/services/apiServices"
import PageLoading from "src/components/pageLoading";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { saveAs } from "file-saver";

const useStyles = makeStyles((theme) => ({
  grayBox: {
    width: "100%",
    height: "auto",
    backgroundColor: "#FAFAFA",
    display: "flex",
    justifyContent: "center",
  },

  mainBox: {
    width: "51%",
    margin: "5rem",
    backgroundColor: "inherit",

    "@media(max-width:900px)": {
      width: "100%",
    },

    "@media(max-width:500px)": {
      margin: "1rem",
    },
  },

  reportContent: {
    //  width: "40%",
    //   height: "50%",
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "10px",
    color: "red"
  },

  firstBox: {
    width: "100%",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center,",
  },

  typo: {
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "32px",
    lineHeight: "38px",
    color: "#6C757D",
  },

  topDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    gap: "10px",

    "@media(max-width:600px)": {
      flexWrap: "wrap",
    },
  },

  select: {
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "32px",

    "&:before": {
      borderBottom: "none",
    },

    "&:after": {
      borderBottom: "none",
    },

    "& .MuiSelect-select.MuiSelect-select": {
      padding: "3px 27px",
      background: " #ffffff",
      borderRadius: "5px",
    },

    "& .MuiSelect-icon": {
      left: "8.5rem",
    },
  },

  bottomDiv: {
    textAlign: "center",

    "& p": {
      fontFamily: "Poppins",
      fontWeight: "400",
      fontSize: "16px",
      color: "#6C757D",
      margin: "0",
    },
  },

  middleBox: {
    marginTop: "1rem",
  },

  summaryheading: {
    backgroundColor: "#D6D6D6",
    padding: "10px 15px",
    borderRadius: "8px 8px 0 0",

    "& p": {
      fontFamily: "Poppins",
      fontWeight: "400",
      fontSize: "20px",
      color: "#495057",
      margin: "0",
    },
  },

  textAndResult: {
    display: "flex",
    flexDirection: "column",
    padding: "5px",
    backgroundColor: "#FFFFFF",
  },

  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& p": {
      fontSize: "16px",
      fontFamily: "Poppins",
      fontWeight: "400",
      color: "#495057",
    },
  },

  profitContainer: {
    padding: "0 15px",
  },

  textContainer: {
    textAlign: "left",
    padding: "5px 8px",

    "& p": {
      fontSize: "13px",
      fontFamily: "Poppins",
      fontWeight: "400",
      color: "#495057",
    },
  },

  bottomBox: {
    display: "flex",
    flexDirection: "column",
    padding: "5px 20px",
    gap: "5px",

    marginTop: "1.5rem",
  },

  pickReportTxt: {
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: "400",
    color: "#6C757D",
  },

  select1: {
    backgroundColor: "#F4F5F7",
    borderBottom: "none !important",

    "&:before": {
      borderBottom: "none",
    },

    "&:after": {
      borderBottom: "none",
    },
  },

  downloadReportBtn: {
    backgroundColor: "#D64F49",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: "400",
    color: "#FFFFFF",
    textTransform: "none",

    "&:hover": {
      backgroundColor: "#D64F49",
    },
  },
}));

const TaxComponent = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [gainLossData, setGainLossData] = useState({});
  const [reportType, setReportType] = useState('Select...');
  const [showReportContent, setShowReportContent] = useState(false);
  const [showContent, setShowContent] = useState(true);

  const classes = useStyles();
  useEffect(() => {
    getGainsLoss();
  }, []);

  const getGainsLoss = async () => {
    const address = localStorage.getItem("walletAddress");
    const data = {
      "walletAddress": address,
      start_timestamp: "1680307200",
      end_timestamp: "1686825054",
      eth_price_at_transaction: "1788.48",
      eth_purchase_price: "1716.80",
    };
    setLoading(false);
    try {
      setLoading(true);
      const response = await getprofitlossPercentageHandler("gainlossPercentange", data);
      if (response) {
        setGainLossData(response.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleReportTypeChange = (event) => {
    setReportType(event.target.value);
  };

  const handleDownloadReport =  () => {
    setShowReportContent(true);
    setTimeout(async() => {
      if (reportType === 'pdf') {
        // Download report as PDF
        const targetElement = document.getElementById('report-container');

        // Use html2canvas to capture the target element as an image
        const canvas = await html2canvas(targetElement);
        const pdf = new jsPDF();

      
        // Calculate the aspect ratio of the target element and the PDF page
        const elementRatio = targetElement.offsetWidth / targetElement.offsetHeight;
        const pageRatio = pdf.internal.pageSize.getWidth() / pdf.internal.pageSize.getHeight();
      
        let imageWidth;
        let imageHeight;
        if (elementRatio > pageRatio) {
          // If the element is wider than the page, set the width to match the page width
          imageWidth = pdf.internal.pageSize.getWidth();
          imageHeight = imageWidth / elementRatio;
        } else {
          // If the element is taller than the page, set the height to match the page height
          imageHeight = pdf.internal.pageSize.getHeight();
          imageWidth = imageHeight * elementRatio;
        }
          // Add the captured image as a new page in the PDF document
          pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, imageWidth, imageHeight);

          // Save or download the PDF file
          pdf.save('downloaded_screen.pdf');
        
      } else if (reportType === 'Image') {
        // Download report as Image
        const input = document.getElementById('report-container');
        html2canvas(input).then((canvas) => {
          canvas.toBlob((blob) => {
            saveAs(blob, 'report.png');
          });
        });
      }
      setShowReportContent(false);
    }, 0);
  };

  // useEffect(() => {
  //   const reportContainer = document.getElementById('report-container');
  //   if (reportContainer) {
  //     if (showReportContent) {
  //       reportContainer.style.display = 'block';
  //     } else {
  //       reportContainer.style.display = 'none';
  //     }
  //   }
  // }, [showReportContent]);

  return (
    <>
      {loading ? (
        <PageLoading />
      ) : (
        <Box className={classes.grayBox}>
          <Box className={classes.mainBox} >
            <Box id="report-container">
            <Box className={classes.firstBox} >
              <div className={classes.topDiv}>
                <Typography variant="body1" className={classes.typo}>
                  Tax report for
                </Typography>
                <Select
                  defaultValue="2022"
                  className={classes.select}
                  IconComponent={ExpandMoreIcon}
                >
                  <MenuItem value={2022}>2022</MenuItem>
                </Select>
              </div>
              <div className={classes.bottomDiv}>
                <p>31 Mar 2022 to 31 Mar 2023 (+0000)</p>
              </div>
            </Box>

            <Box className={classes.middleBox} >
              <div className={classes.summaryheading}>
                <p>Summary</p>
              </div>

              <div className={classes.textAndResult}>
                <div className={classes.textContainer}>
                  <p>
                    Paws needs your full transaction history (fiat → crypto →
                    crypto → fiat) in order to calculate your tax reports
                    correctly.
                    <br /> The transactions used in this report are summarized
                    below.
                  </p>
                </div>

                <div className={classes.profitContainer} >
                  <div className={classes.flex}>
                    <p>Profit</p>
                    <p>${gainLossData?.profit_average_gain ? gainLossData?.profit_average_gain : "0"}</p>
                  </div>

                  <div className={classes.flex}>
                    <p>Loss</p>
                    <p>${gainLossData?.loss_average_gain ? gainLossData?.loss_average_gain : "0"}</p>
                  </div>

                  <div className={classes.flex}>
                    <p>Capital gains / P&L</p>
                    <p>
                      {gainLossData?.capital_gain
                        ? gainLossData?.capital_gain
                        : "0"}
                    </p>
                  </div>

                  <div className={classes.flex}>
                    <p>Tax</p>
                    <p>$0.00</p>
                  </div>
                </div>
              </div>
            </Box>
            </Box>
            <Box className={classes.bottomBox}>
             
              <Typography variant="body1" className={classes.pickReportTxt}>
                Pick a report type
              </Typography>
              <Select
                value={reportType}
                onChange={handleReportTypeChange}
                defaultValue="Select..."
                className={classes.select1}
                IconComponent={ExpandMoreIcon}
              >
                <MenuItem value="Select....">Select...</MenuItem>
                <MenuItem value="pdf">PDF</MenuItem>
                <MenuItem value="Image">Image</MenuItem>
              </Select>
              <Button
                variant="contained"
                className={classes.downloadReportBtn}
                onClick={handleDownloadReport}
              >
                Download Report
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default TaxComponent;
