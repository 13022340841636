import React from 'react';
import Header from "src/components/header"
import Transaction from "src/views/pages/transaction/transactions"


const index = () => {
  return (
    <>
    <Header />
    <Transaction />
    </>
  )
}

export default index