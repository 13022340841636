import React,{Suspense} from "react";
import Routes from "./routes"
import "./styles/main.css"
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import PageLoading from "src/components/pageLoading"

function App() {
  return (
    <div className="App">
          <Suspense fallback={<PageLoading />}>
      <ToastContainer />
      <Routes />
      </Suspense>
    </div>
  );
}

export default App;
