import React from 'react'
import Header from "src/components/header"
import TaxComponent from "src/views/pages/tax/TaxComponent"; 
const index = () => {
  return (
    <>
    <Header />
    <TaxComponent />
    </>
  )
}

export default index