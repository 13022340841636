import React, { useState, useEffect } from "react";
import {
  Grid,
  makeStyles,
  Box,
  Toolbar,
  CircularProgress,
  Typography,
  TableContainer,
  TableHead,
  Table,
  TableCell,
  Paper,
  TableBody,
  TableRow,
} from "@material-ui/core";
import { toast } from "react-toastify";
import clsx from "clsx";
import { getTransactionhandler } from "src/services/apiServices"
import DataNotFound from "src/components/DataNotFound";
import ButtonCircularProgress from "src/components/ButtonCircularProgress";
import PageLoading from "src/components/pageLoading";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTableCell-head": {
      background: "#FFFFFF",
      borderWidth: "1px 0px 2px 0px !important",
      borderStyle: "solid !important",
      borderColor: "#E7EAF3 !important",
    },
  },
  title: {
    flex: "1 1 100%",
    display: "flex",
    flexDirection: "column",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#6C757E",
  },
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    background: "#FFFFFF",
    border: "1px solid rgba(231, 234, 243, 0.2)",
    boxShadow: "0px 8px 20px rgba(65, 65, 65, 0.2)",
    borderRadius: " 8px",
  },
  mainBox: {
    backgroundColor: "#FFFFFF",
    // height:"560px"
    marginBottom: "10rem",
  },
  absolutePosition: {
    position: "relative",
    padding: "0 10%",
    marginTop: "4rem",
  },
  blocks: {
    display: "flex",
    justifyContent: "center",
  },
  tableHead: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#111111",
    textAlign: "center",
  },
  tablecell: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#585858",
    textAlign: "center",
    "@media(max-width:1000px)": {
      minWidth: '150px',
    },
  },
  iconBorder: {
    borderRadius: "5px !important",
  },
  flexSpan: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },
  colorPrimary: {
    background: "#E7EAF3",
    width: "100%",
    borderRadius: "5px",
  },
  barColorPrimary: {
    background: "#D64F49",
  },
  colorPrimary2: {
    background: "#E7EAF3",
    width: "100%",
    borderRadius: "5px",
  },
  barColorPrimary2: {
    background: "#DB9A04",
  },
  flexSerial: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "center"
  },
  flexSerial1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },

  methodCell: {
    borderRadius: "5px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#585858",
    padding: "8px 0",
    textAlign: 'center',
  },

  transactiontxt: {
    fontFamily: "Poppins !important",
    fontSize: "32px",
    fontWeight: "600",
    color: "#495057",
  },
}));

const Transaction = () => {
  const classes = useStyles();
  const [textToCopy, setTextToCopy] = useState("");
  const [loading, setLoading] = useState(true);
  const [transactionlist, setTransactionList] = useState([]);

  const copyTextToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Address copied to clipboard");
      })
      .catch(() => {
        toast.error("Failed to copy text");
      });
  };

  useEffect(() => {
    getTransactions();
  }, [])
  const getTransactions = async () => {
    const address = localStorage.getItem("walletAddress");
    const data = {
      module: "account",
      action: "txlist",
      address: address
    }
    try {
      const response = await getTransactionhandler("transaction", data);
      if (response) {
        setTransactionList(response);
      }

    } catch (error) {
      console.log(error)
    }finally {
      setLoading(false);
    }
  }
  return (
    <>
      {loading ? (
        <PageLoading />
      ) : (
      <Grid className={classes.mainBox}>
        <Box className={classes.absolutePosition}>
          <Box className={classes.transactionContainer} mb={2}>
            <Typography className={classes.transactiontxt}>
              Transactions
            </Typography>
          </Box>

          <div className={classes.root}>
            <Paper className={classes.paper}>
            {loading ? (
            <ButtonCircularProgress />
          ) : (
            <>
              <TableContainer>
                <Table arial-label="simple table">
                  <TableHead>
                    <TableRow className={classes.root}>
                      <TableCell className={classes.tableHead}>PAW</TableCell>
                      <TableCell className={classes.tableHead}>
                        Transaction Hash
                      </TableCell>
                      <TableCell
                        className={classes.tableHead}
                        style={{ textAlign: "center" }}
                      >
                        Block
                      </TableCell>

                      <TableCell className={classes.tableHead}>From</TableCell>
                      <TableCell className={classes.tableHead}>To</TableCell>
                      <TableCell className={classes.tableHead}>Value</TableCell>
                    </TableRow>
                  </TableHead>
                  {transactionlist && transactionlist.map((value) => {
                    return (
                      <TableBody>
                        <TableRow>
                          <TableCell className={classes.tablecell}>
                            <div
                              className={clsx(
                                classes.flexSerial,
                                classes.methodCell
                              )}
                            >
                              <img src="/images/pawimg.svg" alt="" />

                            </div>
                          </TableCell>

                          <TableCell className={classes.tablecell}>
                            <div className={classes.flexSerial1}>
                              <p className={classes.tablecell}>
                              {value?.hash.slice(0,20) + " " + "..." ? value?.hash.slice(0,20) + "..." : "-"}</p>
                              <img src="/images/copyimg.svg" alt="" onClick={() => copyTextToClipboard(value?.hash)} style={{ cursor: "pointer" }} />
                            </div>
                          </TableCell>

                          <TableCell className={classes.tablecell}>
                            {value?.blockNumber ? value?.blockNumber : "-"}

                          </TableCell>

                          <TableCell className={classes.tablecell}>
                            <div className={classes.flexSerial1}>
                              <p className={classes.tablecell}>{value?.from ? value?.from : "-"}</p>
                              <img src="/images/copyimg.svg" alt="" onClick={() => copyTextToClipboard(value?.from)} style={{ cursor: "pointer" }} />
                            </div>
                          </TableCell>

                          <TableCell>
                            <div className={classes.flexSerial1}>
                              <p className={classes.tablecell}>
                                {value?.to ? value?.to : "Contract=>" + " " + value?.contractAddress}
                              </p>
                              <img src="/images/copyimg.svg" alt="" onClick={() => copyTextToClipboard(value?.to ? value?.to : value?.contractAddress)} style={{ cursor: "pointer" }} />
                            </div>
                          </TableCell>
                          <TableCell className={classes.tablecell}>
                            <div
                              className={classes.flexSerial}
                              style={{ gap: "5px" }}
                            >
                              <p className={classes.tablecell}> {value?.value + " " + "PAW" ? value?.value + " " + "PAW" : "-"}</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )
                  }) 
                   }
                </Table>
              </TableContainer>
              {
                (transactionlist.length === 0 && <DataNotFound />)}
            </>
          )}
            </Paper>
          </div>
        </Box>
      </Grid>
      )}
    </>
  );
};

export default Transaction;
