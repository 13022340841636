import ApiConfig from "src/APIConfig/endpoints";
import axios from "axios";


export const getPortfolioTransactionhandler = async (endPoint, dataToSend) => {
    try {
      const res = await axios({
        method: 'GET',
        url: ApiConfig[endPoint],
        params: dataToSend
      })
      if (res.data.status === "1") {
        return res.data.result
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  }
  export const getTransactionhandler = async (endPoint, dataToSend) => {
    try {
      const res = await axios({
        method: 'GET',
        url: ApiConfig[endPoint],
        params: dataToSend
      })
      if (res.status === 200) {
        return res.data.result
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  }
  export const getBalancehandler = async (endPoint, dataToSend) => {
    try {
      const res = await axios({
        method: 'GET',
        url: ApiConfig[endPoint],
        params: dataToSend
      })
      if (res.status === 200) {
        return res.data.result
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  }
  export const getGainLossHandler = async (endPoint, dataToSend) => {
    try {
      const res = await axios({
        method: 'POST',
        url: ApiConfig[endPoint],
        data: dataToSend
      })
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  }
  export const getTransactionCountHandler = async (endPoint, dataToSend) => {
    try {
      const res = await axios({
        method: 'POST',
        url: ApiConfig[endPoint],
        data: dataToSend
      })
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  }
  export const getprofitlossPercentageHandler = async (endPoint, dataToSend) => {
    try {
      const res = await axios({
        method: 'POST',
        url: ApiConfig[endPoint],
        data: dataToSend
      })
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  }
 export const ExchangeArray = [
  {
    img: "images/BNB.svg", name:"BNB"
  },
  {
    img: "images/TRX.svg", name:"TRX"
  },
   {
    img: "images/Usdc.svg", name:"USDC"
  },
  {
    img: "images/XRP.svg", name:"XRP"
  },
  {
    img: "images/dai.svg", name:"DAI"
  },
  {
    img: "images/SUSD.svg", name:"SUSD"
  },
   {
    img: "images/BNB1.svg", name:"BNB"
  },
  {
    img: "images/USDC1.svg", name:"USDC"
  },
  {
    img: "images/TUSD.svg", name:"TUSD"
  },
  {
    img: "images/USDT.svg", name:"USDT"
  },
  {
    img: "images/GUSD.svg", name:"USDT"
  },
  {
    img: "images/PAX.svg", name:"PAX"
  }
  
 ]
