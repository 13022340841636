import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  InputAdornment,
  TextField,
  Button,
  Grid,
  FormHelperText,
  Typography
} from "@material-ui/core";
import { VictoryPie } from "victory";

import {
  getBalancehandler,
  getprofitlossPercentageHandler,
  getTransactionCountHandler,
} from "src/services/apiServices";


const colorScale = ["#1ED91A", "#FFB800", "#C2231C"];

const style = {
  data: {
    stroke: "#FFFFFF",
    strokeWidth: 1,
  },
};

const useStyles = makeStyles((theme) => ({
  mainBox: {
    backgroundColor: "#FAFAFA",
    padding: "1%",
  },
  innerBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    height: "auto",
    alignItems: "center",
    backgroundColor: "#FAFAFA",
  },
  searchContainer: {
    width: "50%",
    margin: "32px 0",
    display: "flex",
    justifyContent: "center",
    gap: "1.25rem",

    padding: "20px",

    "@media only screen and (max-width: 900px)": {
      flexFlow: "column",
    },
    "@media only screen and (max-width: 550px)": {
      flexFlow: "column",
      width: "86%",
    },
  },

  searchtxt: {
    width: "100%",
    borderRadius: "8px",
    "& .MuiOutlinedInput-input": {
      padding: "11px",
    },
    "& .MuiOutlinedInput-root": {
      border: "none ",
      background: "#FFFFFF",
      "& input::placeholder": {
        fontSize: "13px",
        color: "#6C757D",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "400",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  },
  searchDiv: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    "@media(max-width: 550px)": {
      width: "100%",
    },
  },
  gridContainer: {
    width: "80%",
    height: "100%",
    marginTop: "22px",
    padding: "10px",
  },
  searchbtn: {
    backgroundColor: "#D64F49",
    color: "#FFFFFF",
    fontFamily: "Inter",
    fontWeight: "700",
    fontSize: "10px",
    width: "100%",
    maxWidth: "130px",
    "@media(max-width:550px)": {
      fontSize: "10px",
      maxWidth: "77%",
    },
  },
  buttonDiv: {
    width: "100%",
    maxWidth: "170px",
  },

  plusIcon: {
    borderRadius: "5px",

    "@media(max-width:550px)": {
      width: "12px",
    },
  },

  firstGrid: {
    display: "flex",
    gap: "1.2rem",
    padding: "0px 10px 0px 0px !important",
  },
  gridBox1: {
    width: "100%",
    height: "70%",
    border: "1px solid purple",
    backgroundColor: "#FFFFFF",
  },
  gridBox2: {
    width: "100%",
    height: "28%",
    backgroundColor: "#FFFFFF",
  },
  secondGrid: {
    backgroundColor: "#FFFFFF",
    padding: "12px",

    "@media(max-width:959px)": {
      marginTop: "15px",
    },
  },
  linechartText: {
    display: "flex",
    width: "100%",
    justifyContent: "space-evenly",
    gap: "14px",
  },
  costText: {
    margin: "0",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "0px",
  },
  costSecond: {
    textAlign: "center",
    margin: "0",
  },
  mainTextbox: {
    display: "flex",
    justifyContent: "space-around",
  },
  totalText: {
    fontSize: "20px",
    fontFamily: "Poppins",
    color: "gray",

    "@media(max-width:1023px)": {
      fontSize: "19px",
    },

    "@media(max-width:665px)": {
      fontSize: "12px",
    },
  },
  zeroText: {
    color: "#D64F49",
    fontSize: "28.32px",
    margin: "0",
    fontFamily: "Poppins",
    fontWeight: "400",

    "@media(max-width:550px)": {
      fontSize: "18.32px",
    },
  },
  zeroPercent: {
    color: "#C9CBCF",
    fontSize: "16.18px",
    fontFamily: "Poppins",
    fontWeight: "400",

    "@media(max-width:550px)": {
      fontSize: "11.8px",
    },
  },
  costSecond: {
    color: "#C9CBCF",
    fontSize: "28.32px",
    fontFamily: "Poppins",
    fontWeight: "400",
    textAlign: "center",
    margin: "0",

    "@media(max-width:550px)": {
      fontSize: "18.32px",
    },
  },
  capitalGains: {
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "28.32px",
    color: "#FFB800",
    margin: "0",
    "@media(max-width: 400px)": {
      fontSize: "20px",
    },
  },
  chartDiv: {
    display: "flex",
    justifyContent: "center",

    "& .recharts-wrapper": {
      position: "relative",
      cursor: "default",
      width: "600px",
      height: "600px",
      display: "flex",
      justifyContent: "center",
    },

    "& .recharts-surface": {
      width: "100%",
      height: "100%",
    },
  },
  portText: {
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24.27px",
    color: "#6C757D",
    "@media(max-width:400px)": {
      fontSize: "14px",
    },
  },
  portfoilioDiv: {
    display: "flex",
    flexDirection: "column",
  },
  balanceText: {
    textAlign: "center",
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24.27px",
    color: "#6C757D",

    "@media(max-width:400px)": {
      fontSize: "13px",
    },
  },
  barChartDiv: {
    display: "flex",
    justifyContent: "center",
    border: "1px solid #DEE2E6",
    borderRadius: "8px",

    "& .recharts-wrapper": {
      position: "relative",
      cursor: "default",
      width: "500px",
      height: "300px",
      display: "flex",
    },

    "& .recharts-surface": {
      width: "100%",
      height: "117%",
    },
  },
  pieDiv: {
    display: "flex",
    justifyContent: "center",
  },
  gridContent: {
    display: "flex",
    justifyContent: "flex-start",
    gap: "16px",
    alignItems: "center",
  },
  gridText: {
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "24.27px",
    color: "#495057",
    "@media(max-width:400px)": {
      fontSize: "17px",
    },
  },
  gridText1: {
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19.42px",
    color: "#6C757D",
    "@media(max-width:400px)": {
      fontSize: "14px",
    },
  },
  Box1: {
    height: "100%",
    backgroundColor: "#FFFFFF",
    borderRadius: "12px",
  },
  Box2: {
    height: "100%,",
    backgroundColor: "#FFFFFF",
    borderRadius: "12px",
  },
  Box3: {
    height: "100%,",
    backgroundColor: "#FFFFFF",
    borderRadius: "12px",
  },
  gridContain: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "0 12%",
  },
  gridContain2: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "20px",
    "@media(max-width: 959px)": {
      width: "100%",
    },
  },
  grid1: {
    width: "80%",
    gap: "2%",
    display: "flex",
    justifyContent: "space-around",

    "@media(max-width: 950px)": {
      height: "50%",
    },
  },
  grid2: {
    width: "100%",
    height: "70%",
    marginTop: "10px",
  },
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  firstpic: {
    display: "flex",
  },
  secondpic: {
    display: "flex",
    borderRadius: "4px",
    backgroundColor: "#E8FFF3",
    padding: "3px 10px",
  },
  dollar: {
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "20px",
    color: "#A1A5B7 !important",
  },
  number1: {
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "34px",

    "@media(max-width: 652px)": {
      fontSize: "20px",
    },
  },
  addressText: {
    marginTop: "-20px",
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "14px",
    color: "#A1A5B7",
  },
  gridBox2: {
    backgroundColor: "white",
    borderRadius: "8px",
    padding: "25px",
    display: "flex",
    "@media(max-width: 599px)": {
      flexFlow: "column",
    },
  },
  secondDiv: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "50px",
  },
  secondText: {
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "13px",
    color: "#6C757D",

    "@media(max-width: 400px)": {
      fontSize: "10px",
    },
  },
  zeroP: {
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "28.32px",
    color: "#1ED91A",
    margin: "0",

    "@media(max-width: 400px)": {
      fontSize: "20px",
    },
  },
  Zerop: {
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "28.32px",
    color: "#C2231C",
    margin: "0",
    "@media(max-width: 400px)": {
      fontSize: "20px",
    },
  },
  mainBox1: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    "@media(max-width: 652px)": {
      flexWrap: "wrap",
    },
  },
  respBox: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  error: {
    color: "red",
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: "600",

    "@media(max-width:684px)": {
      fontSize: "8px",
    },
  },
  blocks: {
    display: "flex",
    justifyContent: "center",
  },
  Indexblock: {
    background: "#FFFFFF",
    borderRadius: "5px",
    height: "auto",
    boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.03)",
  },
  indexblockFlex: {
    display: "flex",
    alignItems: "center",
    gap: "14px",
  },
  indexblockText: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& h1": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "18px",
      letterSpacing: "00.1em",
      color: "#D64F49",
    },
    "& p": {
      display: "flex",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "21px",
      letterSpacing: "00.1em",
      color: "#A1A5B7",
      marginTop: "0",
      "@media (max-width: 599px)": {
        fontSize: "12px",
      },
    },
    "& span": {
      fontFamily: "Poppins !important",
      fontStyle: "normal !important",
      fontWeight: "600 !important",
      fontSize: "14px !important",
      lineHeight: "18px !important",
      letterSpacing: "00.1em !important",
      color: "#77838F !important",
    },
  },

  label: {
    fontFamily: "Poppins !important",
    fontWeight: "700 !important",
    fontSize: "22px !important",
    color: "#585858 !important",

    "@media (max-width: 599px)": {
      fontSize: "12px !important",
    },
  },
}));

const Header = () => {
  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  });
  const updateSize = () => {
    setSize({
      x: window.innerWidth,
      y: window.innerHeight,
    });
  };
  useEffect(() => (window.onresize = updateSize), []);
  const classes = useStyles();
  const [address, setAddress] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [displayAddress, setDisplayAddress] = useState(
    localStorage.getItem("walletAddress")
      ? localStorage.getItem("walletAddress")
      : ""
  );
  const [balance, setBalance] = useState("");
  const [loading, setLoading] = useState(true);
  const [gainLossData, setGainLossData] = useState({});
  const [transactionData, setTransactionData] = useState(0);
  const [pieData, setpieData] = useState([]);
  const [hoveredSection, setHoveredSection] = useState(null);

  const handleChange = (event) => {
    const inputAddress = event.target.value;
    setAddress(inputAddress);
    setIsValid(validateAddress(inputAddress));
  };

  const validateAddress = (inputAddress) => {
    const regex = /^0x[a-fA-F0-9]{40}$/;
    return regex.test(inputAddress);
  };
  useEffect(() => {
    setDisplayAddress(displayAddress);
  }, [displayAddress]);

  const addWalletAddress = async () => {
    await localStorage.setItem("walletAddress", address);
    const displayAddress = localStorage.getItem("walletAddress");
    setDisplayAddress(displayAddress);
  };
  const wallet = localStorage.getItem("walletAddress");
  useEffect(() => {
    getBalance();
    getGainsLoss();
    getTransactionCount();
  }, [balance, transactionData, wallet]);

  const getBalance = async () => {
    const address = localStorage.getItem("walletAddress");
    const data = {
      module: "account",
      action: "balance",
      address: address,
    };
    setLoading(false);
    try {
      setLoading(true);
      const response = await getBalancehandler("balance", data);
      if (response) {
        setBalance(response);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const getGainsLoss = async () => {
    const address = localStorage.getItem("walletAddress");
    const data = {
      walletAddress: address,
      start_timestamp: "1680307200",
      end_timestamp: "1686825054",
      eth_price_at_transaction: "1788.48",
      eth_purchase_price: "1716.80",
    };
    setLoading(false);
    try {
      setLoading(true);
      const response = await getprofitlossPercentageHandler(
        "gainlossPercentange",
        data
      );
      if (response) {
        setGainLossData(response?.data);
        setLoading(false);
        setpieData([
          { y: response?.data.profit_average_gain_percentage },
          { y: response?.data.capital_gain_percentage },
          { y: response?.data.loss_average_gain_percentage },
        ]);
      } else {
        setGainLossData(null);
        setpieData([]);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const isPieDataEmpty = pieData.length === 0 || pieData.every(data => data.y === 0);


  const options = {
    tooltips: {
      enabled: false,
    },
    onHover: (event, elements) => {
      if (elements.length > 0) {
        const sectionIndex = elements[0]._index;
        setHoveredSection(sectionIndex);
      } else {
        setHoveredSection(null);
      }
    },
  };

  const getTransactionCount = async () => {
    const address = localStorage.getItem("walletAddress");
    const data = {
      walletAddress: address,
    };
    setLoading(false);
    try {
      setLoading(true);
      const response = await getTransactionCountHandler(
        "transactionCount",
        data
      );
      if (response) {
        setTransactionData(response);
        setLoading(false);
      }
    } catch (error) {
      alert("e")
      setLoading(false);
      console.log("error", error?.response)
      // console.log(error);
    }
  };

  const Height = {
    height: size.y,
  };

  return (
    <div>
      <Box className={classes.mainBox} style={{ height: Height.height }}>
        <Box className={classes.innerBox}>
          <Box className={classes.searchContainer}>
            <div className={classes.searchDiv}>
              <TextField
                className={classes.searchtxt}
                variant="outlined"
                placeholder="Wallet Address"
                type="text"
                id="address"
                value={address}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src="images/bitcoin-wallet.svg"
                        alt="bitcoin-wallet"
                      />
                    </InputAdornment>
                  ),
                }}
              />
              {!isValid && (
                <FormHelperText error className={classes.error}>
                  Invalid address format
                </FormHelperText>
              )}
            </div>
            <div className={classes.buttonDiv}>
              <Button
                variant="contained"
                className={classes.searchbtn}
                startIcon={
                  <img
                    src="images/Plus.svg"
                    alt="plusicon"
                    className={classes.plusIcon}
                  />
                }
                onClick={addWalletAddress}
              >
                {" "}
                Add Wallet
              </Button>
            </div>
          </Box>
          <Grid container className={classes.gridContain}>
            <Grid
              item
              container
              spacing={4}
              className={classes.blocks}
              style={{ gap: 12 }}
            >
              <Grid item xs={12} sm={10} md={7} className={classes.Indexblock}>
                <div className={classes.indexblockFlex}>
                  <div className={classes.indexblockText}>
                    <div>
                      <p>ADDRESS</p>
                    </div>
                    <div>
                      <label className={classes.label}>{displayAddress}</label>
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={5} md={2} className={classes.Indexblock}>
                <div className={classes.indexblockFlex}>
                  <div className={classes.indexblockText}>
                    <div>
                      <p>BALANCE</p>
                    </div>
                    <div>
                      <label className={classes.label}
                        style={{ overflowWrap: "anywhere" }}>{balance ? balance : "-"}</label>
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={5} md={2} className={classes.Indexblock}>
                <div className={classes.indexblockFlex}>
                  <div className={classes.indexblockText}>
                    <div>
                      <p>TRANSACTIONS</p>
                    </div>
                    <div>
                      <label className={classes.label}>{transactionData ? transactionData : "-"}</label>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid container className={classes.gridContain2}>
              <Grid item xs={12} sm={12} md={12} className={classes.grid2}>
                <Box className={classes.gridBox2}>
                  <div className={classes.chartDiv}>
                    {isPieDataEmpty ? (
                      <Typography variant="h6" className={classes.noDataText}>
                        No Data Found
                      </Typography>
                    ) : (
                      <VictoryPie
                        data={pieData}
                        options={options}
                        width={500}
                        height={500}
                        colorScale={colorScale}
                        labels={() => ""}
                        style={style}
                      />
                    )}
                  </div>
                  <div className={classes.secondDiv}>
                    <div>
                      <p className={classes.secondText}>Profit</p>
                      <p className={classes.zeroP}>
                        <span style={{ color: "#1ED91A" }}>$</span>
                        {gainLossData?.profit_average_gain
                          ? gainLossData?.profit_average_gain
                          : "0"}
                      </p>
                    </div>
                    <div>
                      <p className={classes.secondText}>Loss</p>
                      <p className={classes.Zerop}>
                        <span style={{ color: "#C2231C" }}>$</span>
                        {gainLossData?.loss_average_gain
                          ? gainLossData?.loss_average_gain
                          : "0"}
                      </p>
                    </div>
                    <div>
                      <p className={classes.secondText}>Capital Gains</p>
                      <p className={classes.capitalGains}>
                        <span style={{ color: "#6C757D" }}></span>
                        {gainLossData?.capital_gain
                          ? gainLossData?.capital_gain
                          : "0"}
                      </p>
                    </div>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {/* </Box> */}
        </Box>
      </Box>
    </div>
  );
};

export default Header;
