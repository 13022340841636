import React from 'react'
import Header from "src/components/header"
import PortfolioComponent from "src/views/pages/portfolio/PortfolioComponent"
const index = () => {
  return (
    <>
<Header/>
<PortfolioComponent/>
    </>
  )
}

export default index