import React, { lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from "src/views/pages/home";
import Dashboard from "src/views/pages/dashboard";
import Transaction from "src/views/pages/transaction";
import Tax from "src/views/pages/tax/index";
import Portfolio from "src/views/pages/portfolio/index";
import Wallet from "src/views/pages/wallet";





const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/transactions" component={Transaction} />
        <Route exact path="/tax" component={Tax} />
        <Route exact path="/portfolio" component={Portfolio} />
        <Route exact path="/wallet" component={Wallet} />
        {/* <Route component={NotFound} /> */}
      </Switch>
    </Router>
  );
};

export default Routes; 
